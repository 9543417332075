<template>
  <div class="baby-shower-layout">
    <main>
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  name: "BabyShowerLayout",
};
</script>
<style lang="scss" src="@/assets/scss/baby-shower-layout.scss"></style>