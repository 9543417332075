import BabyShowerLayout from "@/layouts/BabyShowerLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import OhBabyLayout from "@/layouts/OhBabyLayout.vue";
import ProductLayout from "@/layouts/ProductLayout.vue";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);

document.addEventListener('contextmenu', (event) => {
  event.preventDefault();
});

app.component("DefaultLayout", DefaultLayout);
app.component("ProductLayout", ProductLayout);
app.component("OhBabyLayout", OhBabyLayout);
app.component("BabyShowerLayout", BabyShowerLayout);

app.use(router);

app.mount("#app");